.highlight {
  @apply text-[#212427];
}

.highlight purple {
  color: #7e5dff;
}
.highlight blue {
  color: #7cebff;
}
.highlight red {
  color: #e64a67;
}
