@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-Satoshi text-font-black;
}

.rainbow {
  background: linear-gradient(
    270deg,
    rgb(255, 217, 104) 0%,
    rgb(230, 75, 102) 35.1351%,
    rgb(66, 187, 240) 67.1171%,
    rgb(139, 111, 255) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
